import AddPost from "./components/AddPost";
import Posts from "./components/Posts";
import { useReducer, useEffect, useState } from "react";
import { reducer } from "./reducer";
import { Alert, Skeleton, AlertTitle, Grid } from "@mui/material";
import NavBar from "./components/NavBar";

function App() {
  // initial state of App
  const initialState = { posts: [] };
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);

  // fetching the posts from the workers api
  useEffect(() => {
    fetch(`${process.env.REACT_APP_WORKERS_API}/posts`, { method: "GET" })
      .then((res) => {
        return res.json();
      })
      .then((jsonRes) => {
        dispatch({ type: "SET_POSTS", payload: jsonRes.posts });
        setIsLoaded(true);
      })
      .catch((error) => {
        setIsLoaded(true);
        setError(error.message);
      });
  }, []);

  if (error) {
    return (
      <Grid sx={{ width: "50vw", textAlign: "center", margin: "0 auto" }}>
        <Alert severity="error" variant="outlined">
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      </Grid>
    );
  } else if (!isLoaded) {
    return (
      <Skeleton sx={{ maxWidth: "50vw", margin: "0 auto", height: "25vh" }} />
    );
  } else {
    return (
      <div className="App">
        <NavBar />
        <AddPost dispatch={dispatch} />
        <Posts state={state} dispatch={dispatch} />
      </div>
    );
  }
}

export default App;
