import * as React from "react";
import { Alert, AlertTitle, Grid, Skeleton } from "@mui/material";
import { Fab } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { TextField } from "@mui/material";
import { useState } from "react";

const AddPost = ({ dispatch }) => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [username, setUsername] = useState("");
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);

  // function to handle Adding the Posts to KV Store
  const addPost = async () => {
    if (username !== "" && title !== "" && content !== "") {
      // Post Object
      const post = { username, title, content };

      // setting Loading Status
      setIsLoaded(false);

      // POST request options
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(post),
      };

      await fetch(`${process.env.REACT_APP_WORKERS_API}/posts`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          // updating the existing posts in the reducer
          dispatch({ type: "UPDATE_POSTS", payload: data });
          // clearing form
          setTitle("");
          setContent("");
          setUsername("");
          setIsLoaded(true);
        })
        .catch((error) => {
          // setting error message
          setError(error.message);
          // clearing form
          setTitle("");
          setContent("");
          setUsername("");
          setIsLoaded(true);
        });
    }
  };

  return (
    <>
      {isLoaded && (
        <Grid
          sx={{ marginTop: "50px" }}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid container sx={{ margin: "20px" }} maxWidth="sm">
            <Grid
              spacing={2}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={12}>
                {error && (
                  <Alert severity="error" variant="outlined">
                    <AlertTitle>Error</AlertTitle>
                    {error}
                  </Alert>
                )}
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <TextField
                  fullWidth
                  id="standard-basic"
                  label="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <TextField
                  fullWidth
                  id="standard-basic"
                  label="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  fullWidth
                  id="standard-basic"
                  label="content"
                  multiline
                  rows={4}
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                />
              </Grid>
              <Grid item>
                <Fab onClick={addPost} variant="extended" color="primary">
                  <AddCircleIcon sx={{ mr: 1 }} />
                  Add New Post
                </Fab>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {!isLoaded && (
        <Skeleton
          sx={{ maxWidth: "50vw", margin: "0 auto", height: "350px" }}
        />
      )}
    </>
  );
};

export default AddPost;
