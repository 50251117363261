import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { teal, blueGrey } from "@mui/material/colors";
import React from "react";

const Post = ({ props }) => {
  const { username, content, title } = props;
  return (
    <Card
      sx={{ minWidth: 500 }}
      variant="outlined"
      style={{ backgroundColor: blueGrey[100] }}
    >
      <CardHeader
        avatar={
          <Avatar
            sx={{ bgcolor: teal[500], width: 36, height: 36 }}
            aria-label="recipe"
          >
            {username[0]}
          </Avatar>
        }
        title={<Typography variant="h5">{username}</Typography>}
      ></CardHeader>
      <CardContent>
        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2">{content}</Typography>
      </CardContent>
    </Card>
  );
};

export default Post;
