export const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_POSTS":
      return {
        ...state,
        posts: [action.payload.newPost, ...state.posts],
      };
    case "SET_POSTS":
      return {
        ...state,
        posts: action.payload,
      };
    default:
      Error("YOU DID SOMETHING YOU WERE NOT SUPPOSE TO DO");
      break;
  }
};
