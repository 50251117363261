import { Grid } from "@mui/material";
import React from "react";
import Post from "./Post";
const Posts = ({ state }) => {
  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      {state.posts.map((p) => (
        <Grid item key={Math.random()}>
          <Post props={p} />
        </Grid>
      ))}
    </Grid>
  );
};

export default Posts;
